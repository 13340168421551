/* eslint-disable no-console */
import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal, Snackbar } from '@material-ui/core';
import { MdClear } from 'react-icons/md';
import ContactGroupForm from '../ReservationGroup/ContactGroupForm';
import { withTrans } from '../../i18n/withTrans';
import useAuthContext from '../../contexts/AuthContext';
import { Alert } from '@material-ui/lab';
import { useHandleResize } from '../../utils';

function reducer(state, action) {
  return { ...state, ...action };
}

const ContactFormModal = ({ t, open, setOpen }) => {
  const { dispatchAPI } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, {
    contact: {},
    group: {},
    visible: false,
    newsletter: false,
    openAlert: false
  });
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    setOpen(!open);
  };

  const sendEmail = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/orders/send_email_contact`,
        body: state
      });
    } catch (e) {
      if (e.response) console.error(e.response.status);
    }
  };

  const validateForm = async () => {
    const errors = [];
    if (
      !state.contact.prenom ||
      !state.contact.nom ||
      !state.contact.email ||
      state.errors_validation.includes('email_format') ||
      state.errors_validation.includes('phone_format')
    ) {
      errors.push('contact');
      dispatch({ errors_validation: errors, openValErrorsSnack: true });
    } else {
      dispatch({ errors_validation: [], openValErrorsSnack: false });
    }
    if (!errors.length) {
      setIsLoading(true);
      await sendEmail();
      dispatch({ contact: {}, openAlert: true });
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    dispatch({
      openAlert: false
    });
  };

  const { width, height } = useHandleResize();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width < 920 ? '95vw' : '50%',
    maxWidth: width < 920 ? '95vw' : '50%',
    maxHeight: height < 750 ? '95vh' : 'none',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 3,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: width < 400 ? '24px 16px' : 30,
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'auto',
    outline: 0,
    outlineStyle: 'none'
  };

  const sendDebug = () => {
    dispatchAPI('POST', {
      url: '/debug',
      body: {
        location: 'ContactFormModal.js line 28',
        state: state
      }
    });
  };

  useEffect(() => {
    sendDebug();
  }, [state]);

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div className="contactFormModal" style={style}>
        <Snackbar
          open={state.openAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity="success">{t('contactForm.success')}</Alert>
        </Snackbar>
        <h2 className="modal-title">{t('contactForm.title')}</h2>
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div>
        <ContactGroupForm t={t} dispatch={dispatch} state={state} textBox />
        <div
          style={{
            minWidth: '100%',
            display: 'flex',
            justifyContent: 'end',
            margin: '20px 0 8px 0'
          }}
        >
          <button
            onClick={() => onClose()}
            disabled={isLoading}
            className="btn-secondary"
            style={{ marginRight: 10 }}
          >
            {t('buttons.cancel')}
          </button>
          <button
            className="btn-secondary"
            onClick={() => validateForm()}
            disabled={isLoading}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: isLoading ? 0.7 : 1
            }}
          >
            {isLoading ? (
              <span className="loader" style={{ marginRight: 8 }} />
            ) : (
              t('buttons.send')
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default withTrans(ContactFormModal);

ContactFormModal.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

ContactFormModal.defaultProps = {
  open: undefined
};
