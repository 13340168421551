/* eslint-disable no-console */
import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { useStateWithLocalStorage } from '../utils';

const AuthContext = createContext({});
const axiosInstance = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

export const AuthContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN',
    permission: {
      grant: [],
      permission_label: ''
    }
  });
  const [token, setToken] = useStateWithLocalStorage('token');
  const [isValid, setIsValid] = useState(false);

  const setSession = (accessToken) => {
    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  };
  const logout = () => {
    setSession(null);
    setUser(null);
  };

  const fetchAPI = async (
    url,
    method = 'GET',
    body = null,
    responseType = 'json',
    cancelToken
  ) => {
    try {
      setIsLoading(true);
      const result = await axiosInstance({
        url,
        method,
        responseType,
        cancelToken,
        data: body
      });
      setIsLoading(false);
      return result;
    } catch (e) {
      setIsLoading(false);
    }
  };
  const loginAPI = async (email, password) => {
    try {
      const result = await axiosInstance.post('/login', {
        email,
        password
      });
      setUser(result.data.user);
      setSession(result.data.token);
      return result;
    } catch (e) {
      console.log('e:', e);
    }
  };
  const registerAPI = async (values) => {
    try {
      return await axiosInstance.post('/register', values);
    } catch (e) {
      console.log('e:', e);
    }
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password);
      case 'LOGOUT':
        return logout();
      case 'REGISTER':
        return registerAPI(options);
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        console.error('Unknown dispatchAPI type!');
    }
  };

  return (
    <AuthContext.Provider
      value={{ dispatchAPI, isLoading, user, isValid, token }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default () => useContext(AuthContext);
