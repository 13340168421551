import React, { useState } from 'react';
import './stripe.css';
import { Box, Card } from '@material-ui/core';
import { withTrans } from '../../i18n/withTrans';
import SystemPay from './SystemPay';

const ShowPayment = ({ reservationState, reservationDispatch, t }) => {
  const [messageTitle, setMessageTitle] = useState();
  const [message, setMessage] = useState();

  return (
    <Box className="payment-box">
      <Card className="payment-card">
        <h2>{t('payment.instructions')}</h2>
        <p>{`${t('payment.total')} ${reservationState.totalPrice} €`}</p>
        <div>
          <SystemPay
            state={reservationState}
            setMessageTitle={setMessageTitle}
            setMessage={setMessage}
            reservationDispatch={reservationDispatch}
          />
        </div>
      </Card>
      {message && messageTitle && (
        <Card className="message-card">
          <h2>{t(`payment.${messageTitle}`)}</h2>
          {/* Add returnMessageTitle inside h2 */}
          <p>{t(`payment.${message}`)}</p>
        </Card>
      )}
    </Box>
  );
};

export default withTrans(ShowPayment);
